function Carousel({ count, index, render }) {
  const entries = [];
  for (let i = 0; i < count; i++) {
    let style = i === index ? "carousel-on" : "carousel-off";
    entries.push(render(i, style));
  };

  return entries;
}

export default Carousel;