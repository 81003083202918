import './App.scss';
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import Disclaimer from './components/Disclaimer';
import Home from './pages/Home/Home';
import Event from './pages/Event/Event';
import All from './pages/All/All';
import DataAndMethods from './pages/DataAndMethods/DataAndMethods';
import ThankYou from './pages/ThankYou/ThankYou';
import Header from './components/Header';
import Footer from './components/Footer';

import { logData } from "data/data.js";

function App() {
  const [visited, set_visited] = useState(localStorage.getItem("disclaimer_visited") === "true");

  const onEnter = () => {
    localStorage.setItem("disclaimer_visited", true);
    set_visited(true);
  }

  useEffect(() => {
    const onKeyDown = (evt) => {
      if (evt.ctrlKey && evt.code === "Backquote")
        logData();
    }
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <div id="Container">
      <Disclaimer visited={visited} onEnter={onEnter} />

      <div id="App" className={!visited ? "disclaimer-visible" : ""}>
        <Router>
          <TransitionAnimation>
            <Route path="/event" render={({ location: { search } }) => {
              const id = new URLSearchParams(search).get("id");
              const back = new URLSearchParams(search).get("back");
              return <Event id={id} back={back} />
            }} />
            <Route path="/all">
              <All />
            </Route>
            <Route path="/data-and-methods" render={({ location: { search } }) => {
              const tab = new URLSearchParams(search).get("tab") ?? 0;
              return <DataAndMethods tab={tab} />
            }} />
            <Route path="/thank-you">
              <ThankYou />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </TransitionAnimation>
        </Router>
      </div >
    </div >
  );
}




function TransitionAnimation({ children }) {
  let location = useLocation();
  const isIn = location.pathname == "/"
  const id = { "/": "HomeTransition", "/event": "EventTransition" }[location.pathname] || "EventTransition";
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames={`transition`}
        timeout={1250}
        in={isIn}
      >
        <div id={"EventTransition"} className="transition-root">
          <div className="page page3" />
          <div className="page page2" />
          <div className="page page1" />

          <div className="scroll" id="scroll">
            <Header />
            <div className="body">
              <Switch location={location}>
                {children}
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
