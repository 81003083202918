
import { useState } from "react";

import "./Intro.scss"
import Carousel from "components/Carousel";

// const data = [
//   `Future Wake is an art project that intends to stir discussions around predictive policing and police-related fatal encounters. 

// The ‘Wakes’ are generated using statistics and Artificial Intelligence (A.I.) and are based on 20 years of historical data. The generated victims and stories are not real.

// To read more about Future Wake visit our Data & Methods pages.`,
//   `What is Predictive Policing? 

// Predictive Policing leverages large sets of historical data and statistical models to forecast the likelihood of future crime. 

// Future Wake turns the application of predictive policing upside down, rather than predicting crimes committed by the public it focuses on future fatal encounters with the police. To predict future events, Future Wake uses historical data of past victims of police violence to predict where, when, who and how the next victim will die.`
// ]

function Intro({ title, data }) {
  const [index, set_index] = useState(0);

  const onPrev = () => set_index(index - 1);
  const onNext = () => set_index(index + 1);
  const onClickDot = (i) => set_index(i);

  return (
    <div id="Intro">
      <div className="box">
        <span className="header">{title /*Short answer, No*/}</span>
        {index > 0 && <img src="/assets/SVG/icon_arrow_right_blue.svg" className="prev-button button" onClick={onPrev} />}
        {index < data.length - 1 && <img src="/assets/SVG/icon_arrow_right_blue.svg" className="next-button button" onClick={onNext} />}
        <div className="intro-body">
          <Carousel
            count={data.length}
            index={index}
            render={(i, className) =>
              i === index ?
                <div key={i} className={`carousel-body ${className}`}>{data[index]}</div>
                : null
            }
          />
        </div>
        <IntroProgress count={data.length} index={index} onClickDot={onClickDot} />
      </div>
    </div>
  );
}

function IntroProgress({ count, index, onClickDot }) {
  const dots = [];
  for (let i = 0; i < count; i++)
    dots.push(
      <div key={i} className={`dot ${i === index ? "active" : ""}`} onClick={() => onClickDot(i)}></ div>
    )
  return <div id="IntroProgress">{dots}</div>;
}

export default Intro;
