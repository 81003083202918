import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Markup } from 'react-render-markup';

import "./DataAndMethods.scss";

// import texts from "./texts";
import copy from "copy/data_and_methods.json";

function DataAndMethods({ tab }) {
  const tabCopy = copy.tabs[tab];
  let body = tabCopy.body;

  let embedIndex = 0;
  if (tabCopy.embeds)
    body = body.replaceAll("[embed]", () => `<iframe srcdoc="${tabCopy.embeds[embedIndex++].code}" ></iframe>`);

  return (
    <div id="DataAndMethods">
      <Link to="/" className="back-button text-button">back</Link>

      <div className="tabs">
        {copy.tabs.map((tabCopy, i) =>
          <Link key={i} to={`?tab=${i}`} className={`tab ${tab == i ? "active" : ''}`}>{tabCopy.title}</Link>
        )}
        {/* <Link to={"?tab=0"} className={`tab ${tab == 0 ? "active" : ''}`}>What is Future Wake?</Link>
        <Link to={"?tab=1"} className={`tab ${tab == 1 ? "active" : ''}`}>What is Predictive Policing</Link>
        <Link to={"?tab=2"} className={`tab ${tab == 2 ? "active" : ''}`}>How did we build Future Wake?</Link> */}
      </div>
      <div className="text">
        <div className="side"></div>

        <div className="middle">
          <Markup markup={body} />
        </div>

        <div className="side"></div>
      </div>
    </div>
  )
}


export default DataAndMethods;