import { Link } from "react-router-dom";
import { useState } from "react";

import "./Home.scss"
import EventPreview from "components/EventPreview";
import { citiesData } from "data/data.js";

import copy from "copy/home.json";

function Home() {
  return (
    <div id="Home">
      <div className="section top">
        <div className="tabs">
          <div className="tab active">{copy.first_tab_label /*future wake 0.1 beta*/}</div>
          <div className="empty"></div>
          <div className="empty"></div>
        </div>

        <div className="text">
          {copy.first_tab_body.map(({ line }) => <div key={line} className="line">{line}</div>)}
          {/* <div className="line">This is Future Wake, a wake dedicated to the victims of future police-related fatal encounters. </div>
          <div className="line">Here we predict who, when, where and how the next police-related fatal encounter will occur in</div>
          <div className="line">the 5 most populous cities in the United States.</div> */}
        </div>
      </div>

      <div className="section bottom">
        <div className="tabs">
          <div className="empty"></div>
          <div className="tab active">{copy.second_tab_label /*Next victim forecast in<br />the 5 biggest U.S.A. cities*/}</div>
          <div className="empty"></div>
        </div>
        <PreviewEvents />
        <div className="bottom-text">{copy.second_tab_footer /*Click the portraits to hear the victims’ stories*/}</div>
        <Link to="all" className="see-all-button link">See all predictions ></Link>
      </div>
    </div>
  )
}

function PreviewEvents() {
  return (
    <div id="PreviewEvents">
      {citiesData.map(([event]) => <EventPreview key={event.id} event={event} />)}
    </div>
  )
}


export default Home;