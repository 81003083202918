
import { useState, useEffect, useRef } from "react";
import { useGoogleMapsApi } from "./useGoogleMapsApi";

export function useStreetView(apiKey: string, options: google.maps.MapOptions) {
  const googleMaps = useGoogleMapsApi(apiKey);

  const ref = useRef(null);
  const [streetView, set_streetView] = useState();

  useEffect(() => {
    if (!googleMaps || !ref || !options) {
      return;
    }

    let webService = new googleMaps.StreetViewService();
    // let checkaround = 5000;
    let checkaround = 500;
    let point = new google.maps.LatLng(options.position.lat, options.position.lng);


    webService.getPanoramaByLocation(point, checkaround, (panoData) => {
      if (panoData?.location?.latLng)
        set_streetView(new googleMaps.StreetViewPanorama(ref.current, {
          ...options,
          // pano: panoData,
          position: panoData.location.latLng
        }));
      else
        set_streetView(new googleMaps.StreetViewPanorama(ref.current, options));
    });

  }, [googleMaps, ref, options]);

  return { ref, streetView, googleMaps };
}