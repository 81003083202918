import { Link } from "react-router-dom";
import Intro from "components/Intro";

import "./Footer.scss";

import copy from "copy/footer.json";
const introData = copy.carousel_body.map(({ body }) => body);

function Footer() {
  return (
    <div id="Footer">
      <div className="tabs">
        <div className="empty"></div>
        <div className="empty"></div>
        <div className="tab">{copy.tab_label /*is this real?*/}</div>
      </div>
      <div className="footer-body">
        <Intro title={copy.carousel_label} data={introData} />
        <Link to="/data-and-methods" className="link">Data & Methods</Link>

        <div className="footer-bottom-text">
          {copy.bottom_text}
        </div>

      </div>
    </div>
  )
}

export default Footer;