import { useState, useRef } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "./EventPreviewMedium.scss";

function EventPreviewMedium({ event, noVideo, linkTop, back }) {
  const { id, city, details, longitude, latitude } = event;
  const { date } = details;

  return (
    <div id="EventPreviewMedium">
      <div className="city"><img src="/assets/SVG/icon_pin_down.svg" alt="pin" className="pin" />{city ?? "???"}</div>
      <Link to={`/event?id=${id}${back ? `&back=${back}` : ''}${linkTop ? "#top" : ""}`} className="media">
        <div className="background">
          <img src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${latitude},${longitude}&radius=500&fov=80&heading=0&pitch=0&key=AIzaSyC5CO1ZXJyz6DTzGGjz5_BoDdLuFIAR21M`}
            className="background-preview" alt="area"
          />
          <div className="noise-overlay" />
        </div>
        <div className="foreground">
          <img loading="lazy" src={`assets/victims/${id}/${id}_victim.png`} className="foreground-preview foreground-content" alt="victim" />
        </div>
      </Link>
      <div className="date-and-time">
        <div className="date">{date}</div>
      </div>
    </div>
  )
}

export default EventPreviewMedium;