import { useState, useRef, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';

import Carousel from "components/Carousel";
import EventPreview from "components/EventPreview";
import StreetView from "components/StreetView";

import { aliveData, citiesData, eventData } from "data/data.js";

import "./Event.scss";

function Event({ id, back }) {
  const [playing, set_playing] = useState(false);
  const [progress, set_progress] = useState(0);
  const [videoLoaded, set_videoLoaded] = useState(false);
  const [muted, set_muted] = useState(false);
  const [captions, set_captions] = useState(true);

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current)
      return;

    if (playing)
      ref.current.play();
    else
      ref.current.pause();
  }, [playing, ref]);

  const event = eventData.find(({ id: _id }) => _id === id);

  useEffect(() => {
    // console.log("event", event);
    set_playing(false);
    set_progress(0);
    set_videoLoaded(false);
  }, [event.id]);

  if (!event)
    return null;

  const { transcript, latitude, longitude } = event;

  const onPlay = () => set_playing(true);
  const onStop = () => set_playing(false);

  const onLoadVideo = () => set_videoLoaded(true);

  const onTimeUpdate = ({ target: { readyState, duration, currentTime } }) =>
    readyState && duration && currentTime && set_progress(Math.min(1, (currentTime + 0.25) / duration));

  const onEnded = (evt) => (set_progress(0), set_playing(false));

  const toggleMute = () => set_muted(!muted);

  const toggleCaptions = () => set_captions(!captions);

  const circumference = (25 - 2.5) * Math.PI * 2;

  const tabsData = citiesData.map(([entry]) => entry);
  const previewData = aliveData.filter(entry => entry.city === event.city);

  const roundTo = (val, to) => Math.floor(val * to) / to;
  const transcriptTransform = `translateY(calc(${-roundTo(progress * 80, 100)}%`

  const backUrl = back ?? "/";
  return (
    <div id="Event">
      <Link id="top" to={backUrl} className="back-button text-button">back</Link>
      <Tabs active={event.city} data={tabsData} />
      <div className="event-body">
        <div className="background-streetview">
          <StreetView latitude={latitude} longitude={longitude} />
          <div className="noise-overlay" />
        </div>

        <div className="foreground">
          <div className="left desktop">
            <Details event={event} id="DetailsDesktop" />
          </div>

          <div className="center">
            <div className="victim" key={id}>
              {!videoLoaded && <img src={`assets/victims/${id}/${id}_victim.png`}
                className={`foreground-preview foreground-content`} alt="victim"
              />}

              <video className={`video foreground-content ${!playing ? 'playing' : ''}`}
                muted autoPlay loop playsInline
                onPlaying={onLoadVideo}
                poster={`assets/victims/${id}/${id}_victim.png`}
              >
                <source src={`assets/victims/${id}/${id}_preview.webm`} type="video/webm" />
                <source src={`assets/victims/${id}/${id}_preview.mov`} type="video/quicktime" />
              </video>

              <video className={`video foreground-content ${playing ? 'playing' : ''}`}
                playsInline
                ref={ref}
                muted={muted}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}
              >
                <source src={`assets/victims/${id}/${id}_story.webm`} type="video/webm" />
                <source src={`assets/victims/${id}/${id}_story.mov`} type="video/quicktime" />
              </video>

              <div className="controls">
                <img src={`/assets/SVG/icon_sound_${muted ? "off" : "on"}.svg`} className="mute" onClick={toggleMute} />
                <div className="play-pause">
                  <img src="/assets/SVG/icon_play.svg" className="play" onClick={onPlay} hidden={playing} />
                  <img src="/assets/SVG/icon_stop.svg" className="stop" onClick={onStop} hidden={!playing} />
                  <svg
                    className="progress-ring"
                    width="100%"
                    height="100%">
                    <circle
                      style={{ transition: playing ? "stroke-dashoffset 0.25s linear" : null }}
                      fill="transparent"
                      cx="50%"
                      cy="50%"
                      strokeDashoffset={(1 - progress) * circumference}
                      strokeDasharray={`${circumference} ${circumference}`} />
                  </svg>
                </div>
                <img src={`/assets/SVG/icon_cc_${captions ? "on" : "off"}.svg`} className="captions" onClick={toggleCaptions} />
              </div>
            </div>

            {/* <div className="transcript" style={{ marginBottom: 10 }}>
              <div className="text" style={{ transform: transcriptTransform }}>
                progress: {progress}
              </div>
            </div> */}
            <div className="transcript" hidden={!captions}>
              <div className="text" style={{ transform: transcriptTransform }}>
                {transcript}
              </div>
            </div>
          </div>

          <div className="right desktop"></div>
        </div>
      </div>

      <div className="desktop">
        {!!previewData.length && <EventPreviewCarousel data={previewData} active={event.id} />}
      </div>
      <div className="mobile">
        <Details event={event} id="DetailsMobile" />
        {!!previewData.length && <EventPreviewList data={previewData} active={event.id} />}
      </div>
      {/* <Link to="all" className="view-all-button link underline">VIEW ALL</Link> */}
    </div >
  )
}

function Details({ event, id }) {
  return (
    <div id={id}>
      <div className="title">more info</div>
      <div className="arrow arrow-down">V</div>
      <div className="details">
        {Object.entries(event.details).map(([key, value]) =>
          <div key={key} className="detail">{key}: {value}</div>
        )}
      </div>
      <div className="arrow arrow-up">V</div>
    </div>
  );
}

function Tabs({ data, active }) {
  return (
    <div id="Tabs" >
      {data.map((entry, i) => {
        const isActive = active === entry.city;
        return (
          <Link key={i} to={`/event?id=${entry.id}`} className={`tab text-button ${isActive ? "active" : ""}`}>
            <img src={`/assets/SVG/icon_pin${isActive ? "_down" : ""}.svg`} alt="pin" className="pin" />
            {entry.city}
          </Link>
        );
      }
      )}
    </div >
  );
}

function EventPreviewCarousel({ data, active }) {
  const [index, set_index] = useState(0);

  useEffect(() => {
    set_index(Math.max(Math.min(index, data.length - 5), 0));
  }, [active]);

  const onPrev = () => set_index(index - 1);
  const onNext = () => set_index(index + 1);

  return (
    <div id="EventPreviewCarousel">
      <div className="blue-line" />

      <div className="container">
        {<img src="/assets/SVG/icon_arrow_right_blue.svg" className="prev-button button" onClick={onPrev} hidden={index == 0} />}
        <div className="carousel-container">
          <div className="black-line" />
          <Carousel
            count={data.length}
            index={index}
            render={(i, className) => {
              const entry = data[i];
              const dist = i - index;
              return dist >= 0 && dist < 5 ?
                <Link to={`/event?id=${entry.id}#top`} key={entry.id} className={`carousel-item ${className} ${entry.id === active ? 'active' : ''}`}>
                  <EventPreviewSmall event={entry} wrapperId="EventPreviewSmall" />
                </Link>
                : null
            }}
          />
        </div>
        {<img src="/assets/SVG/icon_arrow_right_blue.svg" className="next-button button" onClick={onNext} hidden={index >= data.length - 5} />}
      </div>
    </div>
  )
}

function EventPreviewList({ data, active }) {
  return (
    <div id="EventPreviewList">
      {data.map(entry =>
        <div key={entry.id} className={`list-item ${entry.id === active ? 'active' : ''}`}>
          <EventPreview event={entry} wrapperId="EventPreviewSmallMobile" linkTop noVideo />
        </div>
      )}
    </div>
  );
}

function EventPreviewSmall({ event, wrapperId }) {
  const { details, area, id, longitude, latitude } = event;
  const { date } = details;

  return (
    <div id={wrapperId}>
      <div className="date">{date}</div>
      <div className="media">
        <img src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${latitude},${longitude}&radius=500&fov=80&heading=0&pitch=0&key=AIzaSyC5CO1ZXJyz6DTzGGjz5_BoDdLuFIAR21M`}
          className="background-image" />
        <div className="foreground">
          <img src={`assets/victims/${id}/${id}_victim.png`} alt="" className="foreground-image" />
        </div>
      </div>
      <div className="neighbourhood">{area ?? "???"}</div>
    </div>
  );
}

export default Event;