import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';

import "./Header.scss";

function Header() {

  const [animationData, set_animationData] = useState();

  useEffect(() => {
    import('assets/logo-animation.json').then(set_animationData);
  }, [])

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid' }
  };

  const scrollDown = () => {
    const scrollEl = document.getElementById("scroll");
    scrollEl.scrollTo({ behavior: "smooth", top: scrollEl.scrollHeight });
  }

  return (
    <div id="Header">
      <div className="top">
        <Link to="/" className="logo">
          {/* <img src="/assets/SVG/logo_header_temp.svg" alt="future wake" className="logo-img" /> */}
          <Lottie options={lottieOptions} height="100%" className="lottie" />
        </Link>
      </div>

      <div className="bottom">
        <div className="section left">
          <div className="blue-line" />
        </div>
        <div className="section right">
          <div className="link text-button" onClick={scrollDown}>is this real?</div>
          <Link to="/data-and-methods" className="link">data & methods</Link>
          <Link to="/thank-you" className="link">thank you</Link>
        </div>
      </div>
    </div>
  );
}

export default Header;