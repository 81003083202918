import { useState, useRef } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "./EventPreview.scss";

function dateDiff(d1, d2) {
  var diff = d1 - d2;
  return isNaN(diff) ? NaN : {
    diff: diff,
    ms: Math.floor(diff % 1000),
    s: Math.floor(diff / 1000 % 60),
    m: Math.floor(diff / 60000 % 60),
    h: Math.floor(diff / 3600000 % 24),
    d: Math.floor(diff / 86400000)
  };
}

function EventPreview({ event, noVideo, linkTop }) {
  const { id, city, details, time, area, longitude, latitude } = event;
  const { date } = details;

  const [videoLoaded, set_videoLoaded] = useState(false);
  const onLoadVideo = () => set_videoLoaded(true);

  const diff = dateDiff(time, new Date().getTime());
  const countDown = `${diff.d.toString().padStart(2, '0')}days ${diff.h.toString().padStart(2, '0')}hrs ${diff.m.toString().padStart(2, '0')}min`

  return (
    <div id="EventPreview">
      <div className="city"><img src="/assets/SVG/icon_pin_down.svg" alt="pin" className="pin" />{city ?? "???"}</div>
      <div className="neighbourhood">{area ?? "???"}</div>
      <Link to={`/event?id=${id}${linkTop ? "#top" : ""}`} className="media">
        <div className="background">
          <img src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${latitude},${longitude}&radius=500&fov=80&heading=0&pitch=0&key=AIzaSyC5CO1ZXJyz6DTzGGjz5_BoDdLuFIAR21M`}
            className="background-preview" alt="area"
          />
          <div className="noise-overlay" />
        </div>
        <div className="foreground">
          {!videoLoaded && <img loading="lazy" src={`assets/victims/${id}/${id}_victim.png`} className="foreground-preview foreground-content" alt="victim" />}
          {!noVideo && <video className="video foreground-content"
            autoPlay muted loop playsInline
            onLoadedData={onLoadVideo}
            poster={`assets/victims/${id}/${id}_victim.png`}
          >
            <source src={`assets/victims/${id}/${id}_preview.webm`} type="video/webm" />
            <source src={`assets/victims/${id}/${id}_preview.mov`} type="video/quicktime" />
          </video>
          }

          <div className="date-and-time">
            <div className="date">{date}</div>
            <div className="time">{countDown}</div>
          </div>
        </div>
      </Link>
      <Link to={`/event?id=${id}${linkTop ? "#top" : ""}`} className="event-button link underline">View Event</Link>
    </div>
  )
}

export default EventPreview;