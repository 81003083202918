
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { deadData, monthsData } from "data/data";
import EventPreviewMedium from "components/EventPreviewMedium";
import "./All.scss";

function All() {
  return (
    <div id="All">
      <Link id="top" to="/" className="back-button text-button">back</Link>
      <div className="tabs">
        <div className="tab active">All Predictions</div>
        <div className="empty"></div>
        <div className="empty"></div>
      </div>
      <PreviewDeadEvents />
      <PreviewEvents />
    </div >
  )
}

function PreviewDeadEvents() {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      const scrollEl = document.getElementById("scroll");

      const bottom = ref.current.getBoundingClientRect().bottom
        - scrollEl.getBoundingClientRect().top
      const top = Math.max(bottom - 400, bottom + 400 - window.innerHeight);
      scrollEl.scrollTo({ top });
    }
  }, [ref]);

  return (
    <div id="PreviewDeadEvents" ref={ref}>
      <div className="previewEvents">
        <div className="past">past</div>
        {deadData.map(event => <EventPreviewMedium key={event.id} event={event} back="all" />)}
      </div>
    </div>
  )

}

function PreviewEvents() {
  return (
    <div id="PreviewEvents">
      <div className="future">future</div>
      {monthsData.map(({ month, data }) => <div key={month} className="previewEvents">
        <div className="month">{month}</div>
        {data.map(event => <EventPreviewMedium key={event.id} event={event} back="all" />)}
      </div>)}
    </div>
  )
}

export default All;