import eventData from "data/event-data";

const missingMediaData = eventData.filter(e => e.missingMedia.length);
const readyData = eventData.filter(e => !e.missingMedia.length);

const deadData = readyData.filter(({ time }) => time <= new Date().getTime());
const aliveData = readyData.filter(({ time }) => time > new Date().getTime());

const cities = [
  "Chicago",
  "Houston",
  "Los Angeles",
  "New York",
  "Phoenix",
];

const citiesData = cities
  .map(city => {
    const entries = aliveData.filter(entry => entry.city == city);
    return entries.length ? entries : [[...readyData].reverse().find(entry => entry.city == city)];
  })

const months = [...new Set(aliveData.map(d => d.month))];
const monthsData = months.map(m => ({ month: m, data: aliveData.filter(d => d.month == m) }));

const logData = () => {
  console.log("eventData", eventData);
  console.log("missingMediaData", missingMediaData);
  console.log("readyData", readyData);
  console.log("deadData", deadData);
  console.log("aliveData", aliveData);
  console.log("citiesData", citiesData);
  console.log("monthsData", monthsData);
}

export {
  eventData,
  deadData,
  aliveData,
  cities,
  citiesData,
  monthsData,
  logData
}

