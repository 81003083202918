
import { useState, useRef, useEffect } from "react";
import { useStreetView } from "hooks/useStreetView";

import "./StreetView.scss";

const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

function Streetview({ latitude, longitude }) {

  const [heading, set_heading] = useState(0);
  const [view, set_view] = useState(null);

  const [streetViewOptions, set_streetViewOptions] = useState(null);

  // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
  const { streetView, ref, googleMaps } = useStreetView("AIzaSyC5CO1ZXJyz6DTzGGjz5_BoDdLuFIAR21M", streetViewOptions);

  useEffect(() => {
    set_streetViewOptions(
      {
        position: { lat: latitude, lng: longitude },
        pov: { heading: 0, pitch: 0 },
        zoom: 1,
        disableDefaultUI: true,
        addressControl: false,
        clickToGo: false,
        scrollwheel: false,
        showRoadLabels: false
      });
  }, [longitude, latitude]);

  useEffect(() => {
    let interval;
    if (!mobile) {
      interval = requestAnimationFrame(() => {
        set_heading(heading + 0.05);
      });

      if (streetView)
        streetView.setPov({ heading, pitch: 0 });
    }
    return () => clearInterval(interval);
  }, [heading, streetView]);

  return (
    <div ref={ref} id="StreetView">StreetView</div>
  );
}

export default Streetview;