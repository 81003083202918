import { useEffect, useState } from "react";
import Lottie from 'react-lottie';

import "./Disclaimer.scss"

function Disclaimer({ visited, onEnter }) {
  const [hide, set_hide] = useState(false);
  const [animationData, set_animationData] = useState();
  const [animationDataLines, set_animationDataLines] = useState();

  useEffect(() => {
    if (visited) {
      setTimeout(() => set_hide(true), 1000);
    }
  }, [visited]);

  useEffect(() => {
    import('assets/logo-big-animation.json').then(set_animationData);
    import('assets/logo-big-lines-animation.json').then(set_animationDataLines);
  }, [])

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    }
  };

  const lottieOptionsLines = {
    loop: true,
    autoplay: true,
    animationData: animationDataLines,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    }
  };

  if (hide)
    return null;

  return (
    <div id="Disclaimer" className={`${visited ? "visited" : ""}`} >
      <div className="section top"></div>

      <div className="section center">
        {/* <img src="/assets/SVG/logo_intro_temp.svg" alt="future wake" /> */}
        <div className="logo">
          <Lottie options={lottieOptions} height="50vmin" width="100%" className="lottie" />
          <div className="lines">
            <Lottie options={lottieOptionsLines} height="50vmin" width="100%" className="lottie" />
          </div>
        </div>
        <div className="subtitle">An art project about future fatal encounters with the police</div>
      </div>

      <div className="section bottom">
        <div className="disclaimer">
          This website contains sensitive content relating to death, violence and police brutality. <br />
          It is recommended that viewers are 18 or over.
        </div>
        <div className="enter-button link text-button" onClick={onEnter}>Enter Experience &gt;</div>
      </div>

      <div className="section mobile"></div>
    </div >
  )
}

export default Disclaimer