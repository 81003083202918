import { Link } from "react-router-dom";
import { Markup } from 'react-render-markup';

import "./ThankYou.scss";

// import text from "./text";
import copy from "copy/thank_you.json";

function ThankYou() {
  return (
    <div id="ThankYou">
      <Link to="/" className="back-button text-button">back</Link>

      <div className="tabs">
        <div className="tab active">{copy.title /*thank you*/}</div>
        <div className="empty"></div>
        <div className="empty"></div>
      </div>
      <div className="text">
        <div className="side"></div>
        <div className="middle">
          <Markup markup={copy.body} />
        </div>
        <div className="side"></div>
      </div>
    </div>
  )
}

export default ThankYou;